import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import { P } from '../shared/text'

interface QuestionInterface {
  question: string
  answer: React.ReactNode
}

interface FaqDataInterface {
  label: string
  questionList: QuestionInterface[]
}

const faqData: FaqDataInterface[] = [
  {
    label: 'About',
    questionList: [
      {
        question: 'What are Grummies?',
        answer: (
          <P>
            Grummies are real superfood gummies, made with powerful superfoods, organic sweeteners,
            flavors from actual fruit, and certifications that count. No fake stuff you can't
            pronounce.
          </P>
        ),
      },
      {
        question: 'Do you offer free shipping?',
        answer: <P>We offer free shipping for subscriptions and orders $30+.</P>,
      },
      {
        question: 'Do you have a brand ambassador program?',
        answer: (
          <P>
            If you're interested in partnering with Grummies, we would love to hear from you! Please
            contact us at{' '}
            <OutboundLink
              href="mailto:hello@eatyourgrummies.com"
              target="_blank"
              rel="noopener nofererrer"
            >
              hello@eatyourgrummies.com
            </OutboundLink>
            .
          </P>
        ),
      },
      {
        question: 'Do you have a wholesale program?',
        answer: (
          <P>
            We're always interested in great distributor partnerships! Please contact us at{' '}
            {
              <OutboundLink
                href="mailto:hello@eatyourgrummies.com"
                target="_blank"
                rel="noopener nofererrer"
              >
                hello@eatyourgrummies.com
              </OutboundLink>
            }{' '}
            and we will be in touch.
          </P>
        ),
      },
      {
        question: 'Do you ship to PO boxes?',
        answer: <P>We do ship to PO boxes.</P>,
      },
      {
        question: 'What payment methods do you accept?',
        answer: (
          <P>
            Payments are processed securely through Stripe. We support Visa, Mastercard, and Amex.
          </P>
        ),
      },
      {
        question: 'How can I get in touch with a product question or a question about my order?',
        answer: (
          <P>
            We're happy to help! Contact us at{' '}
            <OutboundLink
              href="mailto:support@eatyourgrummies.com"
              target="_blank"
              rel="noopener nofererrer"
            >
              support@eatyourgrummies.com
            </OutboundLink>
            .
          </P>
        ),
      },
    ],
  },
  {
    label: 'Products',
    questionList: [
      {
        question: 'Are Grummies products gluten-free?',
        answer: <P>All Grummies products are gluten-free. They are also vegan and gelatin-free.</P>,
      },
      {
        question: 'Are Grummies products vegetarian?',
        answer: <P>All Grummies products are vegetarian and vegan.</P>,
      },
      {
        question: 'Are Grummies products vegan?',
        answer: <P>All Grummies products are vegan.</P>,
      },
      {
        question: 'Are Grummies products non-GMO?',
        answer: <P>All Grummies products are non-GMO.</P>,
      },
      {
        question: 'Are Grummies products made in the USA?',
        answer: (
          <P>
            All Grummies products are proudly made in the USA from global ingredients, in a
            GMP-certified facility.
          </P>
        ),
      },
      {
        question: 'Can I take multiple Grummies products in combination with one another?',
        answer: (
          <P>
            Absolutely! We take a holistic approach to our gummies and our different product
            offerings are complementary to each other so you can best achieve your health and
            wellness goals.
          </P>
        ),
      },
      {
        question: 'How should I store my Grummies products?',
        answer: <P>Store your gummy bottles in a cool, dry place, away from direct sunlight.</P>,
      },
      {
        question: 'What Grummies products are okay to give to my children?',
        answer: (
          <P>
            Though products like our elderberry gummies are widely considered safe for children, we
            recommend consulting with your doctor before making any diet changes to children under
            18.
          </P>
        ),
      },
      {
        question: 'Can I take more than the recommended dose?',
        answer: <P>We do not recommend exceeding the suggested daily dose.</P>,
      },
      {
        question: "Can I take Grummies products if I'm pregnant or breastfeeding?",
        answer: (
          <P>
            We recommend you consult with your doctor before making any changes to your diet while
            pregnant or breastfeeding.
          </P>
        ),
      },
      {
        question: 'Does it matter when I take my Grummies?',
        answer: (
          <P>
            Feel free to take your Grummies whenever is convenient for you, although many customers
            prefer to take ashwagandha in the evening due to its calming benefits.
          </P>
        ),
      },
    ],
  },
  {
    label: 'Orders',
    questionList: [
      {
        question: 'How long will it take my order to arrive?',
        answer: (
          <P>
            You should receive your order in 3-5 business days once it has shipped. However, due to
            the effects of the pandemic on global logistics, there may be delays with transit times.
          </P>
        ),
      },
      {
        question: 'Can I track my package?',
        answer: (
          <P>
            We will send a shipping confirmation email once your order is on its way, which will
            include a tracking link.
          </P>
        ),
      },
      {
        question: 'How can I modify or cancel my order?',
        answer: (
          <P>
            Please reach out to us at{' '}
            <OutboundLink
              href="mailto:support@eatyourgrummies.com"
              target="_blank"
              rel="noopener nofererrer"
            >
              support@eatyourgrummies.com
            </OutboundLink>
            .
          </P>
        ),
      },
      {
        question: 'What is your refund policy?',
        answer: (
          <P>
            We hope you'll love our products, but in case you don't, we offer a 30-day money-back
            guarantee. Please reach out to us at{' '}
            {
              <OutboundLink
                href="mailto:support@eatyourgrummies.com"
                target="_blank"
                rel="noopener nofererrer"
              >
                support@eatyourgrummies.com
              </OutboundLink>
            }{' '}
            with your Order ID within 30 days of your received order to begin the returns process.
            <br />
            <br />
            Once initiated, the refund amount will be credited to the original payment method in 7 -
            10 business days.
          </P>
        ),
      },
    ],
  },
  // {
  //   label: 'Subscription',
  //   questionList: [
  //     {
  //       question: 'Is there a minimum order amount for a subscription?',
  //       answer: <P>There is not a minimum order amount to begin or continue a subscription.</P>
  //     },
  //     {
  //       question: 'Do I need to create an account to manage my subscription?',
  //       answer: (
  //         <P>
  //           You don't need to create an account to manage your subscription. Upon order confirmation, there will be a link in your order
  //           confirmation email where you can manage all your subscription settings without needing to create an account. However, we do
  //           suggest creating an account in the event you misplace that link or delete the email.
  //         </P>
  //       )
  //     },
  //     {
  //       question: "Help! I can't find where to manage my subscription settings.",
  //       answer: (
  //         <P>
  //           You can create an account with us anytime (with the email you used to create your subscription) and change subscription settings
  //           for any past or future orders. Alternatively, if you prefer not to create an account with us, there is a link in your order
  //           confirmation email where you can modify your subscription settings.
  //         </P>
  //       )
  //     },
  //     {
  //       question: 'How do I update my credit card or billing information?',
  //       answer: <P>Log into your account and click on "Manage Subscriptions" under the "Account Details" column.</P>
  //     },
  //     {
  //       question: 'How do I update my shipping information?',
  //       answer: <P>Log into your account and click on "View Addresses" under the "Account Details" column.</P>
  //     },
  //     {
  //       question: 'How do I change my subscription delivery frequency?',
  //       answer: <P>Log into your account and click on "Manage Subscriptions" under the "Account Details" column.</P>
  //     },
  //     {
  //       question: 'How do I remove or add products in my subscription?',
  //       answer: <P>Log into your account and click on "Manage Subscriptions" under the "Account Details" column.</P>
  //     },
  //     {
  //       question: 'How do I change my next subscription order date?',
  //       answer: <P>Log into your account and click on "Manage Subscriptions" under the "Account Details" column.</P>
  //     },
  //     {
  //       question: 'How do I cancel my subscription?',
  //       answer: (
  //         <P>
  //           We hate to see you go, but understand that a subscription isn't for everyone. To cancel your subscription, log into your account
  //           and click on "Manage Subscriptions" under the "Account Details" column.
  //         </P>
  //       )
  //     }
  //   ]
  // }
]

export default faqData
